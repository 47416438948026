import React from 'react'
import Logo from '../../Source/Desktop/Logo.svg'
import './Navbar.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

const Navbar = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <div className="navbar">
            <div className="navbar__logo">
                <img src={Logo} alt="logo" />
            </div>

            <div className="navbar__list">
                <ul>
                    <Link to='/'>
                        <li>{t("Navbar.home")}</li>
                    </Link>
                    <Link to='/nosotros'>
                        <li>{t("Navbar.us")}</li>
                    </Link>
                    <Link to='/servicios'>
                        <li>{t("Navbar.services")}</li>
                    </Link>
                    <Link to='/contacto'>
                        <li>{t("Navbar.contact")}</li>
                    </Link>
                    <div>
                        <span onClick={() => i18n.changeLanguage("es")}>ES</span> |
                        <span onClick={() => i18n.changeLanguage("en")}>EN</span>
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default Navbar