import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import Application from './Components/Application/Application';
import './index.css'
import {I18nextProvider} from "react-i18next"
import i18next from 'i18next'
import global_es from "./Translations/es/global.json"
import global_en from "./Translations/en/global.json"

i18next.init({
  interpolation: {escapeValue: false}, 
  lng: "es",
  resources:{
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    }
  }
})

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <Application/>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);



