import React from 'react'
import './Categories.scss'
import Apartment from '../../Source/Desktop/Apartamento.jpeg'
import House from '../../Source/Desktop/Casa.jpeg'
import Cowork from '../../Source/Desktop/Conjunto.jpeg'




const Categories = () => {
    return (
        <div className='categories'>
            <div className="categories__container">
                <div className="categories__container__text">
                    <h3>CATEGORIAS</h3>
                    <h2>Busca lo que mejor encaje mejor contigo</h2>
                </div>
                <div className="categories__container__images">
                    <div className="categories__container__images__card">
                        <img src={Apartment} alt="categoria" />
                        <h3>Inversión Tradicional</h3>
                    </div>
                    <div className="categories__container__images__card">
                        <img src={House} alt="categoria" />
                        <h3>Inversión Por Habitaciones</h3>
                    </div>
                    <div className="categories__container__images__card">
                        <img src={Cowork} alt="categoria" />
                        <h3>Inversión Conjunta</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Categories