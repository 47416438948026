import React from 'react'
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import './Calendly.scss'

const Calendly = () => {

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => console.log(e.data.payload),
    });

    return (
        <div className="calendly">
            <div className="calendly__text">
                <h3>AGENDA CON NOSOTROS</h3>
                <h2>TU CITA</h2>
            </div>
            <div className="calendly__container">
                <InlineWidget styles={{ height: '70vh' }} url="https://calendly.com/henrrymb/30min" />
            </div>
        </div>
    )
}

export default Calendly