import React from 'react'
import Navbar from '../Navbar/Navbar'
import video from '../../Source/Desktop/GoodRents.mp4'
import './Start.scss'

const Start = () => {
    return (
        <div className='start'>
            <Navbar />
            <video autoPlay playsInline loop muted className="video"><source src={video} type="video/webm" /></video>
            <div className='start__container'>
                <div className='start__container__subcontainer'>
                    <h1>Find your perfect home with husly</h1>
                    <p>With over 20 years of experience, we at Husly are pioneers in the real estate business. We believe there's a perfect home for everybody, no matter the budget. That's why we always find the best homes for your budget.</p>
                </div>
            </div>
        </div>
    )
}

export default Start