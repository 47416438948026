import React from 'react'
import './Footer.scss'
import Logo from '../../Source/Desktop/Logo.svg'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__left">
                <div className="footer__left__logo">
                    <img src={Logo} alt="Logo" />
                    <p>© GoodRent</p>
                </div>
                <div className="footer__left__text">
                    <p>With over 20 years of experience, we at Husly are pioneers in the real estate business. We believe there's a perfect home for everybody, no matter the budget. That's why we always find the best homes for your budget.</p>
                </div>
            </div>
            <div className="footer__right">
                <div className="footer__right__container">
                    <h3>Enlaces</h3>
                    <ul>
                        <li>Inicio</li>
                        <li>Nosotros</li>
                        <li>Servicios</li>
                        <li>Contacto</li>
                    </ul>
                </div>
                <div className="footer__right__container">
                    <h3>Localización</h3>
                    <p>Madrid, España</p>
                </div>
                <div className="footer__right__container">
                    <h3>Enlaces</h3>
                    <ul>
                        <li>+34 700000001</li>
                        <li>Correo@empresa.com</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer