import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Contacto from '../../Views/Contacto/Contacto'
import Home from '../../Views/Home/Home'
import Nosotros from '../../Views/Nosotros/Nosotros'
import Servicios from '../../Views/Servicios/Servicios'

const Application = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/nosotros" element={<Nosotros />} />
                <Route path="/servicios" element={<Servicios />} />
            </Routes>
        </div>
    )
}

export default Application