import './Home.scss'

import React from 'react'
import Start from '../../Components/Start/Start'
import Footer from '../../Components/Footer/Footer'
import Categories from '../../Components/Categories/Categories'
import Calendly from '../../Components/Calendly/Calendly'

const Home = () => {
    return (
        <div>
            <Start />
            <Categories />
            <Calendly />
            <Footer />
        </div>
    )
}

export default Home